import React, { Component } from 'react'
import rapchrLogo from '../images/logo-single.svg'

import { formatUserName, renderUserNameClass } from '../utils'


const renderUserImage = (user) => {

  if(user.image) {
    return (
      <div
        className="recommended-user__details__image"
        style={{backgroundImage: `url(${user.image})`}}
      >
      </div>
    )
  } else {

    return (
      <div
        className="recommended-user__details__image rec-default-img"
      >
      </div>
    )
  }
}

const handleArtistClick = (e, artist, onArtistClick) => {
  e.stopPropagation()
  onArtistClick(artist)
}

const renderArtist = (artist, index, onArtistClick, ratingPage, onboarding, currentUser, canClick) => {
  const artistimage = artist.smallImage ? artist.smallImage : artist.image
  if(artistimage) {
    return (
      <div
        onClick={(e) => currentUser && (canClick || (currentUser && ratingPage && onArtistClick)) ? handleArtistClick(e, artist, onArtistClick) : () => {}} key={index}
        className={`recommended-user__artist-wrapper${(!canClick || !currentUser) ? " onboarding" : ""}${ratingPage ? " rating-page" : ""}`}
      >
        <div
          className='recommended-user__artist__image'
          style={{backgroundImage: `url(${artistimage})`}}
        >
        </div>
        <p className={`recommended-user__artist__name${artist.name.length > 13 ? " large" : ""}`}>{artist.name}</p>
      </div>
    )
  } else {
    return (
      <div
        onClick={(e) => (currentUser && canClick) || (currentUser && ratingPage && onArtistClick) ? handleArtistClick(e, artist, onArtistClick) : () => {}} key={index}
        className={`recommended-user__artist-wrapper${!canClick ? " onboarding" : ""}${ratingPage ? " rating-page" : ""}`}
      >
        <div
          className='recommended-user__artist__image'
        >
        </div>
        <p className={`recommended-user__artist__name${artist.name.length > 13 ? " large" : ""}`}>{artist.name}</p>
      </div>
    )
  }
}

const RecommendedUser = ({
  user,
  skills,
  artists,
  index,
  onArtistClick,
  ratingPage,
  onboarding,
  currentUser,
  updatePage,
  customClass
}) => {

  const canClick = (!onboarding) || (!updatePage && onboarding)

  return (
    <div
      className={`recommended-user__wrapper${!canClick ? " onboarding" : ""}${artists ? ' artists-wrapper' : ' skills-wrapper'}${index % 2 !== 0 ? " pushed" : ""}${customClass ? customClass : ""}`}
      onClick={canClick ? () => window.location.replace('/user/' + user.id ) : () => {}}
    >
      <div className={`recommended-user__details ${artists ? 'artists-details' : 'skills-details'}`}>
        {renderUserImage(user)}
        <div className="recommended-user__details__info-wrapper">
          <p className={`recommended-user__details__name ${renderUserNameClass(user.name)}`}>{formatUserName(user.name)}</p>
          {user.location && <p className="recommended-user__details__location">{user.location}</p>}
        </div>
      </div>
      {(skills || artists) &&
        <div className={artists ? 'recommended-user__artists' : 'recommended-user__skills'}>
        {artists && user.favoriteArtists && user.favoriteArtists.map((artist, index) => {
            return renderArtist(artist, index, onArtistClick, ratingPage, onboarding, currentUser, canClick)
        })}
        {skills && user.skillRankings && user.skillRankings.skillRankings && user.skillRankings.skillRankings.map((skill, index) => {
          return (
            <div key={index} className="recommended-user__skill-wrapper">
              <p className="recommended-user__skill-name" key={index}>{skill.name}</p>
            </div>
          )
        })}
        </div>
      }
    </div>
  )
}


export default RecommendedUser
