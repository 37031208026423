import React, {useState, useEffect, useReducer} from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Link } from 'react-router-dom'
import { isMobile } from "react-device-detect";
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";

import Home from './pages/Home'
import UpdateSkills from './pages/UpdateSkills'
import UpdateArtists from './pages/UpdateArtists'
import ArtistRating from './pages/ArtistRating'
import ListBuilder from './pages/ListBuilder'
import About from './pages/About'
import Profile from './pages/Profile'
import ErrorBoundary from './components/ErrorBoundary'
import ErrorPage from './components/ErrorPage'
import Footer from './components/Footer'
import Terms from './pages/legal/Terms'
import Privacy from './pages/legal/Privacy'
import Input from './components/Input'
import Icon from './components/Icon/Icon'
import SharePrompt from './components/SharePrompt'
import Post from './pages/Post'
import PostSearch from './pages/PostSearch'
import Research from './pages/Research'


import './styles/App.scss'
import fullLogo from './images/full-logo.svg'
import loadingIcon from './images/load-disk.png';
import { quotes } from './quotes'
import logoSingle from './images/BT_Filled_Secondary_Logo_Black.svg'
import twitter from './images/twitter-blk.svg'
import instagram from './images/instagram-blk.svg'
import copyIcon from './images/copy-icon.svg';


ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
gaOptions: {
    cookieFlags: 'SameSite=None;Secure'
}});

const pathname = window.location.pathname;

// const NoMatch = ({ location }) => (
//   <ErrorPage noMatch={true} />
// )

const renderQuote = (quote) => {
  return (
    <div className="quote__wrapper">
      <p className="quote__msg">"{quote.message}"</p>
      <p className="quote__name">- {quote.name}</p>
    </div>
  )
}

const handleAccessRedirect = (e, state, dispatch, history) => {
  if(e.target.value === process.env.REACT_APP_MASTER_PASSWORD) {
    if(pathname !== "/" && pathname !== "/get-access") {
      history.replace("/")
    }
  }
  dispatch({payload: {masterPassword: e.target.value}})
}

const renderCookieConsent = () => {
  return <CookieConsent
    location="bottom"
    buttonText="Accept"
    cookieName="myAwesomeCookieName2"
    style={{ background: "#2B373B" }}
    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
    expires={150}
    containerClasses="cookies__container"
    buttonClasses="cookies__button"
    buttonWrapperClasses="cookies__button__wrapper"
    contentClasses="cookies__content-wrapper"
  >
    We use cookies to give you the best experience possible.
    <span className="cookies-msg" style={{ fontSize: "10px" }}><Link className="cookies__link" to="/privacy-policy">Learn More</Link></span>
  </CookieConsent>
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  didLoad: false,
  about: true,
  renderShareModal: true,
  urlCopied: false
})

const App = ({client}) => {
  const [state, dispatch] = useReducer(reducer, initialState())

  const style = state.didLoad ? {} : {visibility: 'hidden'};


  let history = useHistory();

  // history.listen(location => {
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
  // });

  useEffect(() => {
      return history.listen((location) => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
      })
   },[history])

  const {
    data,
    loading,
    error
  } = useQuery(CURRENT_USER_QUERY)

  const {
    data: skillsData,
    loading: skillsLoading,
    error: skillsError
  } = useQuery(ALL_SKILLS_QUERY)

  if(!data || !data.currentUser) {
    if((state.masterPassword !== process.env.REACT_APP_MASTER_PASSWORD) && (data && !data.currentUser) && !pathname.includes("graphic") && !pathname.includes("reset-password") && !pathname.includes("terms") && !pathname.includes("privacy")) {
      const url = process.env.REACT_APP_MAILCHIMP_URL;

      const SimpleForm = () => <MailchimpSubscribe url={url}/>

      return (
        <div className="default-app">
          <div className="default-app__social-wrapper social-header">
            <a rel="noreferrer" target="_blank" href="https://twitter.com/rapchr"><img className="default-app__icon" alt="twitter" src={twitter} /></a>
            <a rel="noreferrer" target="_blank" href="https://www.instagram.com/joinrapchr/"><img className="default-app__icon" alt="instagram" src={instagram} /></a>
          </div>
          <img className="default-app__logo" alt="Rapchr" src={logoSingle} />
          {isMobile && <img className="default-app__gif" alt="rapchr" src="https://res.cloudinary.com/rapchr/image/upload/v1622161596/IMG_9015_zkmc4l.gif" />}
          {!isMobile && <div className="default-app__video__wrapper">
            <video className="default-app__video" autoPlay="autoplay" loop="loop" muted>
              <source src="https://res.cloudinary.com/rapchr/video/upload/v1621250325/IMG_9015_lexnde.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>}
          {state.about &&
            <div className="default-app__about-section">
              <div className="default-app__about-header">
                A digital space <span>dedicated to rap</span>
              </div>
              <p className="default-app__about-msg">
                Rapchr is a new social platform for hip-hop fans to explore and express their love for the art form. Discuss and debate with like-minded fans, enjoy new ways to champion your favorite artists, and chronicle your music listening journey, all in one place!
                <b> Sign up here for launch updates!</b>
              </p>
            </div>
          }
          {!state.about && <p className="default-app__message">sign up for launch updates</p>}
          <div className="default-app__subscribe-wrapper">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => {
                  return (
                    <div>
                      <SimpleForm url={url} onSubmitted={formData => subscribe(formData)} />
                      {status === "sending" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>sending...</div>}
                      {status === "error" && <div style={{ color: "#722a1f" + 10, marginBottom: "20px" }} dangerouslySetInnerHTML={{__html: message}}/>}
                      {status === "success" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>Subscribed !</div>}
                    </div>
                  )
                }}
              />
          </div>
          {!state.about &&
          <div>
            <hr style={{width: "260px"}}></hr>
            <p className="default-app__access-message">or get access now with the password</p>
            <Input
              placeholder="Access Password"
              customClass="default-app__input"
              onChange={(e) => handleAccessRedirect(e, state, dispatch, history)}
              value={state.masterPassword}
              inputType="password"
            />
          </div>}
          <div className="default-app__footer">
            <div className="default-app__social-wrapper social-footer">
              <a rel="noreferrer" target="_blank" href="https://twitter.com/rapchr"><img className="default-app__icon" alt="twitter" src={twitter} /></a>
              <a rel="noreferrer" target="_blank" href="https://www.instagram.com/joinrapchr/"><img className="default-app__icon" alt="instagram" src={instagram} /></a>
            </div>
            <p onClick={() => dispatch({payload: {about: !state.about}})} className="default-app__about-link default-app__footer-link">{state.about ? "Get Access" : "About"}</p>
            <a className="default-app__contact default-app__footer-link" href="mailto:support@joinrapchr.com">Contact Us</a>
          </div>
        </div>
      )
    }
  }


  if((!loading || !skillsLoading) && (data && skillsData)) {
    return (
      <div className="app-wrapper">
        <ErrorBoundary currentUser={data && data.currentUser && data.currentUser}>
          <Switch>

            <Route exact path="/" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/research" render={(props) => <Research allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/about" render={(props) => <About allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/posts" render={(props) => <PostSearch allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/privacy-policy" render={(props) => <Privacy currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/terms-of-use" render={(props) => <Terms currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/reset-password/:resetPasswordToken/" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/forget-password" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/get-access" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/signup" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/get-access/:userId" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/login" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/update-profile" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/update-skills" render={(props) => <UpdateSkills allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/update-rappers" render={(props) => <UpdateArtists currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/rate" render={(props) => <ArtistRating appState={state} allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/rate/search" render={(props) => <ArtistRating appState={state} allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/rate/rapper/:slug/" render={(props) => <ArtistRating appState={state} allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/rate/rapper/:userId/:slug/" render={(props) => <ArtistRating appState={state} allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/rate/graphic/:userId/:slug/" render={(props) => <ArtistRating appState={state} allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/user/:userId" render={(props) => <Profile allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/list-builder" render={(props) => <ListBuilder currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/:userId" render={(props) => <Home allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route exact path="/p/:slug" render={(props) => <Post allSkills={skillsData && skillsData.allSkills && skillsData.allSkills} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
            <Route render={(props) => <ErrorPage noMatch={true} currentUser={data && data.currentUser && data.currentUser} {...props} />} />
          </Switch>
          {renderCookieConsent()}
        </ErrorBoundary>
      </div>
    );
  } else {
    return (
      <div className="app-wrapper loading-app-wrapper">
        <div style={style} className="loading-app-wrapper__content">
          {fullLogo && <img style={style} onLoad={() => dispatch({payload: {didLoad: true}})}  className="loading-logo" src={fullLogo} alt="Rapchr" />}
          <div className="loading__wrapper load-page-wrapper">
            <img className="loading__icon inner" alt="loading" src={loadingIcon} />
          </div>
        </div>
      </div>
    )
  }

}

// {renderQuote(quotes[Math.floor(Math.random() * quotes.length)])}

const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      id
      name
      onboardedArtists
      onboardedSkills
      location
      image
      email
      resetPasswordToken
      resetPasswordExpires
      pageVisits
      userRatingCount
      roles {
        name
      }
      favoriteArtists {
        id
        image
        name
      }
      skillRankings {
        skillRankings {
          id
          name
        }
      }
      signedUpThrough {
        id
        name
        image
        favoriteArtists {
          id
          smallImage
          image
        }
      }
    }
  }
`


const ALL_SKILLS_QUERY = gql`
  query allSkills {
    allSkills {
      id
      name
      description
      categories
    }
  }
`

export default withRouter(App)
